import React from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {Loading, NotFound} from 'src/shared/reactstrap-toolbox'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faCog} from '@fortawesome/free-solid-svg-icons'
import Index from 'src/views/Index'

import AssessmentForm from 'src/views/Assessment/AssessmentForm'
import WellnessStart from 'src/views/Assessment/WellnessStart'
import WellnessForm from 'src/views/Assessment/WellnessForm'
import AssessmentSingle from 'src/views/Assessment/AssessmentSingle'

import ResumeActivateWellness from 'src/views/Wellness/ResumeActivateWellness'
import WellnessConsentPage from 'src/views/Wellness/WellnessConsentPage'
import WellnessBarcodePage from 'src/views/Wellness/WellnessBarcodePage'
import WellnessStep2 from 'src/views/Wellness/WellnessStep2'
import WellnessCollectSample from 'src/views/Wellness/WellnessCollectSample'
import WellnessTestActivation from 'src/views/Wellness/WellnessTestActivation'

import {BloodConsent} from 'src/views/Bloods/BloodConsent'
import BloodsActivateStep1 from 'src/views/Bloods/BloodsBarcodePage'
import {BloodsConfirmCustomerDetails} from 'src/views/Bloods/BloodsConfirmCustomerDetails'
import BloodsConfirmation from 'src/views/Bloods/BloodsSecondaryBarcodeConfirmation'
import BloodWhenToTest from 'src/views/Bloods/BloodsWhenToTest'
import BloodsCollectSample from 'src/views/Bloods/BloodsCollectSample'
import BloodsTestActivation from 'src/views/Bloods/BloodsTestActivation'

import ReportPage from 'src/views/Reports/ReportPage'
import BloodReportPage from 'src/views/BloodReports/BloodReportPage'

import CovidTests from 'src/views/Covid/CovidTests'
import TestsList from 'src/views/TestsList'
import Account from 'src/views/Account'
import TestDetails from 'src/views/TestDetails'
import TestKitDetails from 'src/views/TestKitDetails'
import EmailChanged from 'src/views/EmailChanged'
import CreateAccount from 'src/views/CreateAccount'
import ConfirmAccount from 'src/views/ConfirmAccount'
import Login from 'src/views/Login'
import ResetPasswordRequest from 'src/views/ResetPasswordRequest'
import ResetPasswordForm from 'src/views/ResetPasswordForm'
import Logout from 'src/views/Logout'
import ActivatePublic from 'src/views/ActivatePublic'
import ActivateKitStep1 from 'src/views/Covid/ActivateKitStep1'
import ActivateKitStep2 from 'src/views/Covid/ActivateKitStep2'
import Unsubscribe from 'src/views/Unsubscribe'
import Antigen0BookingReference from 'src/views/Antigen0BookingReference'
import Antigen1Form from 'src/views/Antigen1Form'
import Antigen2Instructions from 'src/views/Antigen2Instructions'
import Antigen3Sms from 'src/views/Antigen3Sms'
import Antigen4ResultPhoto from 'src/views/Antigen4ResultPhoto'
import Antigen5WorkflowComplete from 'src/views/Antigen5WorkflowComplete'
import BootsBotb from 'src/views/BootsBotb'
import EmailLink from 'src/shared/EmailLink'
import ActivateView from 'src/views/ActivateView'
import {Narrow, Wide} from './NarrowWide'

import Notice from './Notice'
import ResumeActivateBlood from 'src/views/Bloods/ResumeActivateBlood'

const NarrowLoading = Narrow(Loading)

export default ({error, user, bodyData, progressStep}) => {
  if (error) {
    return <ErrorPage error={error} />
  } else if (user === null) {
    // user request has not yet finished, wait for it to avoid waiting logic in all the components below
    return <NarrowLoading />
  }

  const AuthNarrow = (Component, props = {}) => Narrow(Component, props, user)
  const AuthWide = (Component, props = {}) => Wide(Component, props, user)

  return (
    <Switch>
      <Route exact path="/" render={AuthNarrow(Index, {user, bodyData, progressStep})} />

      <Redirect exact path="/assessment/" to="/assessment/wellness/" />
      <Route exact path="/assessment/wellness/" render={AuthWide(WellnessStart, {user})} />
      <Route exact path="/assessment/wellness/form" render={AuthWide(WellnessForm, {user})} />
      <Route exact path="/assessment/:test_id(\d+)" render={AuthWide(AssessmentSingle, {user})} />
      <Route exact path="/assessment/:test_id(\d+)/:step(\d+)/" render={AuthWide(AssessmentForm, {user})} />

      <Route exact path="/my-reports/blood/:id/" render={AuthNarrow(BloodReportPage, {user})} />
      <Route exact path="/my-reports/:id/" render={AuthNarrow(ReportPage, {user})} />
      <Route exact path="/my-reports/:id/:health_area/" render={AuthNarrow(ReportPage, {user})} />
      <Route exact path="/my-reports/:id/:health_area/:group/:advice/" render={AuthNarrow(ReportPage, {user})} />

      <Route exact path="/tests/" render={AuthNarrow(TestsList, {user})} />
      <Route exact path="/tests/covid" render={AuthNarrow(CovidTests)} />

      <Route exact path="/test-kits/resume/:test_kit_id/" render={AuthNarrow(ResumeActivateBlood)} />
      <Route exact path="/tests/resume/:test_id/" render={AuthNarrow(ResumeActivateWellness)} />

      <Route exact path="/tests/wellness/resume-activation/" render={AuthNarrow(ResumeActivateWellness)} />
      <Route exact path="/tests/wellness/activate/" render={AuthNarrow(WellnessBarcodePage)} />
      <Route exact path="/tests/wellness/consent/:barcode/" render={AuthNarrow(WellnessConsentPage)} />
      <Route exact path="/tests/wellness/activate/:barcode/" render={AuthNarrow(WellnessStep2)} />
      <Route exact path="/tests/wellness/activate/:barcode/:booking_reference" render={AuthNarrow(WellnessStep2)} />
      <Route exact path="/tests/wellness/collect-samples/:barcode/" render={AuthNarrow(WellnessCollectSample)} />
      <Route exact path="/tests/wellness/time-administered/:barcode/" render={AuthNarrow(WellnessTestActivation)} />

      <Route
        exact
        path="/tests/bloods/activate"
        render={AuthNarrow(BloodsActivateStep1, {user, test_version: 'bloods'})}
      />
      <Route
        exact
        path="/tests/bloods/activate/"
        render={AuthNarrow(BloodsActivateStep1, {user, test_version: 'bloods'})}
      />
      <Route exact path="/tests/bloods/activate/:barcode/" render={AuthNarrow(BloodsConfirmCustomerDetails)} />

      <Route
        exact
        path="/tests/sexual-health/activate"
        render={AuthNarrow(BloodsActivateStep1, {user, test_version: 'sh'})}
      />
      <Route
        exact
        path="/tests/sexual-health/activate/"
        render={AuthNarrow(BloodsActivateStep1, {user, test_version: 'sh'})}
      />
      <Route exact path="/tests/sexual-health/activate/:barcode/" render={AuthNarrow(BloodsConfirmCustomerDetails)} />

      <Route exact path="/tests/private/consent/:barcode" render={AuthNarrow(BloodConsent)} />
      <Route exact path="/tests/private/confirm/:colour/:barcode" render={AuthNarrow(BloodsConfirmation)} />
      <Route exact path="/tests/private/when-should-i-test/:barcode/" render={AuthNarrow(BloodWhenToTest)} />
      <Route exact path="/tests/private/collect-samples/:test_kit_id/" render={AuthNarrow(BloodsCollectSample)} />
      <Route exact path="/tests/private/time-administered/:barcode/" render={AuthNarrow(BloodsTestActivation)} />

      <Route exact path="/tests/pcr/activate/" render={AuthNarrow(ActivateKitStep1)} />
      <Route exact path="/tests/pcr/activate/:barcode/" render={AuthNarrow(ActivateKitStep2)} />
      <Route exact path="/tests/pcr/activate/:barcode/:booking_reference" render={AuthNarrow(ActivateKitStep2)} />

      <Route exact path="/tests/antigen/" render={AuthNarrow(Antigen0BookingReference)} />
      <Route exact path="/tests/antigen/form/:booking_reference" render={AuthNarrow(Antigen1Form)} />
      <Route exact path="/tests/antigen/:token/instructions/" render={Narrow(Antigen2Instructions, {user})} />
      <Route exact path="/tests/antigen/:token/sms/" render={Narrow(Antigen3Sms, {user})} />
      <Route exact path="/tests/antigen/:token/photo/" render={Narrow(Antigen4ResultPhoto, {user})} />
      <Route exact path="/tests/antigen/:token/complete/" render={Narrow(Antigen5WorkflowComplete, {user})} />
      <Route exact path="/boots-best-of-the-best/" render={Narrow(BootsBotb)} />

      <Route exact path="/tests/:test_id/" render={AuthNarrow(TestDetails, {user})} />
      <Route exact path="/test-kits/:test_kit_id/" render={AuthNarrow(TestKitDetails, {user})} />

      <Route exact path="/account/" render={AuthNarrow(Account)} />
      <Route exact path="/logout/" render={AuthNarrow(Logout)} />

      <Route exact path="/activate" render={Narrow(ActivateView, {user})} />
      <Route exact path="/activate-kit/" render={Narrow(ActivatePublic, {user})} />
      <Redirect path="/activate-kit/blood/" to="/tests/bloods/activate" />
      <Redirect path="/activate-kit/sexual-health/" to="/tests/sexual-health/activate" />

      <Route exact path="/create-account/" render={Narrow(CreateAccount, {user})} />
      <Route exact path="/create-account/confirm/:token/" render={Narrow(ConfirmAccount, {user})} />
      <Route exact path="/login/" render={Narrow(Login, {user})} />
      <Route exact path="/password-reset/" render={Narrow(ResetPasswordRequest)} />
      <Route exact path="/password-reset/:blob/" render={Narrow(ResetPasswordForm, {auth_user: user})} />

      <Route exact path="/unsubscribe/" render={Narrow(Unsubscribe)} />
      <Route exact path="/email-changed/" render={Narrow(EmailChanged)} />

      <Route render={Narrow(NotFound)} />
    </Switch>
  )
}

const ErrorPage = ({error}) => {
  if (error.details?.status === 503) {
    return (
      <div className="maintenance">
        <Notice title="Maintenance Mode">
          <FontAwesomeIcon icon={faCog} size="6x" className="loading" />
          <p>This system is currently in maintenance mode, please check back in a few minutes.</p>
          <p>
            If you have any questions, please contact <br /> <EmailLink />.
          </p>
        </Notice>
      </div>
    )
  } else {
    return (
      <Notice title="Snap!">
        <p>
          Looks like something went wrong, we have been notified about this error and will work on a fix as soon as
          time and coffee supplies allow.
        </p>
        <p>
          In the meantime, if you continue experience errors, please contact <EmailLink />.
        </p>
        <p>
          Here's what we know so far:
          <br />
          <span className="text-dark">{error.message || error.toString()}</span>
        </p>
      </Notice>
    )
  }
}
