import React, {useCallback, useEffect, useState} from 'react'
import {Link, RouteComponentProps, withRouter} from 'react-router-dom'
import {Alert, Button, Container, Spinner} from 'reactstrap'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faCheck, faHourglass, faExclamation} from '@fortawesome/free-solid-svg-icons'
import ReactMarkdown from 'react-markdown'

import Clipboard from 'src/assets/icons/Clipboard.svg'
import KitDispatched from 'src/assets/icons/Timeline/Desktop/Icons/3KitDispatched.svg'
import ActivateKit from 'src/assets/icons/Timeline/Desktop/Icons/4CollectSample.svg'
import LabAnalysis from 'src/assets/icons/Timeline/Desktop/Icons/6SampleReceived.svg'
import Doctor from 'src/assets/icons/Doctor.svg'
import PhoneConsultation from 'src/assets/icons/Phone_Consultation_icon.svg'
import ConsultationFailed from 'src/assets/icons/Phone_consultation_FAILv2.svg'
import Results from 'src/assets/icons/Platform/Results.svg'
import Calendly from 'src/Calendly'
import {Loading} from 'src/shared/reactstrap-toolbox'

// Order of statuses is important
const statuses = [
  'error', // Other error - contact customer care
  'created', // Not yet linked to a user
  'activated', // The user has activated with the basic info needed for the rec-engine (Also display as awaiting sample return)
  'processing', // The lab has received the samples (Sample Taken and Returned, or Sample received at Lab, in the lab, or results pending)
  'report-in-review', // In review by the doctor (Name may change depending on Matt's implantation) With Doctor
  'report-ready', // Reviewed by the doctor and ready to view
]

// Order of reasons is important
const errorReasons = [
  'missing-assessment',
  'sample-not-received',
  'lab-delay',
  'unexpected-results',
  'missing-assessment-hormones',
  'untestable',
  'inhibitory',
  'gp-delay',
] as const

type ErrorReason = (typeof errorReasons)[number]

interface StepInstructions {
  test_id: string
  filledAssessment: boolean
  errorReason?: ErrorReason
  errorReasonMessage?: string
  viewInstructionsUrl?: string
  consultation_status?: string
}

interface Step {
  failed?: (
    status: BloodTestStatus,
    errorReason?: ErrorReason,
    consultation_status?: string,
    calendly_status?: string,
    attempt_type?: string,
    outcome?: string,
    archive_reason?: string,
  ) => boolean
  graphic: string
  unconfirmed_result?: boolean
  unconfirmed_result_message?: string
  consultation_status?: string
  calendly_status?: string
  instructions?: ({
    test_id,
    filledAssessment,
    errorReason,
    errorReasonMessage,
    viewInstructionsUrl,
    consultation_status,
  }: StepInstructions) => JSX.Element
  label_before?: string
  label: string
  link?: string
  inProgress: (status: BloodTestStatus, errorReason?: ErrorReason, consultation_status?: string) => boolean
  isCompleted: (status: BloodTestStatus, errorReason?: ErrorReason, consultation_status?: string) => boolean
  hasError?: (status: BloodTestStatus, errorReason?: ErrorReason) => boolean
  isVisible: (unconfirmed_result: any) => boolean
  name?: string
}

// Blood timeline steps
let steps: Step[] = [
  {
    label: 'Test Activated',
    isVisible: (unconfirmed_result: boolean) => {
      return true
    },
    inProgress: (status: BloodTestStatus) => status === 'created',
    isCompleted: (status, errorReason) => {
      const activeIndex = statuses.findIndex(s => s === status)
      const stepIndex = statuses.findIndex(s => s === 'activated')
      return activeIndex >= stepIndex || !!errorReason
    },
    graphic: ActivateKit,
    // link: '/tests/bloods/consent/{test_id}',
  },
  {
    label: 'Assessment Completed',
    isVisible: (unconfirmed_result: boolean) => {
      return true
    },
    label_before: 'Complete Assessment',
    inProgress: (status: BloodTestStatus) => status === 'processing',
    isCompleted: (status, errorReason) => {
      const activeIndex = statuses.findIndex(s => s === status)
      const stepIndex = statuses.findIndex(s => s === 'processing')
      const activeErrorIndex = errorReasons.findIndex(s => s === errorReason)
      const errorIndex = errorReasons.findIndex(s => s === 'missing-assessment' || s === 'missing-assessment-hormones')

      return activeIndex > stepIndex || activeErrorIndex > errorIndex //&& status !== 'error'
    },
    failed: (_status, errorReason) =>
      errorReason === 'missing-assessment' || errorReason === 'missing-assessment-hormones',
    name: 'assessment',
    graphic: Clipboard,
    link: '/assessment/{test_id}/1',
  },
  {
    label: 'Sample Taken & Returned',
    isVisible: (unconfirmed_result: boolean) => {
      return true
    },
    inProgress: (status: BloodTestStatus) => status === 'activated',
    isCompleted: (status, errorReason) => {
      const activeIndex = statuses.findIndex(s => s === status)
      const stepIndex = statuses.findIndex(s => s === 'activated')
      const activeErrorIndex = errorReasons.findIndex(s => s === errorReason)
      const errorIndex = errorReasons.findIndex(s => s === 'sample-not-received')

      return activeIndex > stepIndex || activeErrorIndex > errorIndex
    },
    failed: (_status, errorReason) => errorReason === 'sample-not-received',
    instructions: ({filledAssessment, test_id, viewInstructionsUrl}) => {
      if (filledAssessment) {
        return (
          <>
            <p>
              Your kit has been activated. Please make sure you post it back to us as soon as possible. If you need
              guidance on how to perform your test, please watch our how-to video.
            </p>
            <Button
              tag={'a'}
              href={viewInstructionsUrl}
              target="_blank"
              rel="noopener noreferrer"
              color="primary"
              className="mb-4"
            >
              View instructions
            </Button>
          </>
        )
      }

      return (
        <div>
          <p>
            We have not yet received your <a href={`/assessment/${test_id}/1`}>Patient Health Assessment</a>, please
            submit this as soon as possible.
          </p>
          <p>
            The information you submit will be used by our doctors to review your results with health and lifestyle
            context in mind. <strong>If we do not receive this, you will not get an accurate assessment.</strong>
          </p>
          <p>
            If you are experiencing any difficulties with the accessing or completing your Patient Health Assessment,
            please contact our Customer Care Team via{' '}
            <a href="mailto:care@myhealthchecked.com">care@myhealthchecked.com</a> who are waiting to assist you.
          </p>
        </div>
      )
    },
    graphic: KitDispatched,
  },
  {
    label: 'Analysed at Lab',
    isVisible: (unconfirmed_result: boolean) => {
      return true
    },
    inProgress: (status: BloodTestStatus) => status === 'processing',
    isCompleted: (status, errorReason) => {
      const activeIndex = statuses.findIndex(s => s === status)
      const stepIndex = statuses.findIndex(s => s === 'processing')
      const activeErrorIndex = errorReasons.findIndex(s => s === errorReason)
      const errorIndex = errorReasons.findIndex(s => s === 'lab-delay')

      return activeIndex > stepIndex || activeErrorIndex > errorIndex
    },
    failed: (_status, errorReason) => {
      if (!errorReason) return false
      const reasons: ErrorReason[] = ['lab-delay', 'unexpected-results', 'untestable', 'inhibitory']
      return reasons.includes(errorReason)
    },
    instructions: () => <>Your kit has been received by our laboratory. Analysis of your sample is in progress.</>,
    graphic: LabAnalysis,
  },
  {
    label: 'Reviewed by Doctor',
    isVisible: (unconfirmed_result: boolean) => {
      return true
    },
    inProgress: (status: BloodTestStatus, errorReason?: ErrorReason, consultation_status?) => {
      if (consultation_status === 'consultation' || consultation_status === 'consulted') {
        return false
      } else {
        return status === 'report-in-review' || errorReason === 'gp-delay'
      }
    },
    isCompleted: (status, errorReason, consultation_status?) => {
      if (consultation_status === 'consultation' || consultation_status === 'consulted') {
        return true
      } else {
        const activeIndex = statuses.findIndex(s => s === status)
        const stepIndex = statuses.findIndex(s => s === 'report-in-review')
        return activeIndex > stepIndex && status !== 'error'
      }
    },
    failed: (_status, errorReason) => errorReason === 'gp-delay',
    graphic: Doctor,
    instructions: consultation_status =>
      !consultation_status ? (
        <>Your sample has been analysed by our laboratory. Your results are in review with our doctor.</>
      ) : (
        <></>
      ),
  },
  {
    label: 'Consultation Completed',
    label_before: 'Consultation Required',
    isVisible: (unconfirmed_result: boolean) => {
      return unconfirmed_result
    },
    inProgress: (status: BloodTestStatus, errorReason?: ErrorReason, consultation_status?) => {
      return consultation_status === 'consultation' ? true : false
    },
    isCompleted: (status, errorReason, consultation_status?) => {
      return consultation_status === 'consulted' ? true : false
    },
    failed: (_status, errorReason, consultation_status, calendly_status, attempt_type, outcome) => {
      return calendly_status === 'reschedule_2' && (outcome === 'not_answered' || outcome === 'not_attended')
        ? true
        : false
    },
    graphic: PhoneConsultation,
  },
  {
    label: 'Results Ready',
    isVisible: (unconfirmed_result: boolean) => {
      return true
    },
    label_before: 'Get Results',
    inProgress: (status: BloodTestStatus) => false,
    isCompleted: (status, errorReason) => status === 'report-ready',
    failed: (_status, errorReason, consultation_status?, calendly_status?, attempt_type?, outcome?) => {
      return calendly_status === 'reschedule_2' && (outcome === 'not_answered' || outcome === 'not_attended')
        ? true
        : false
    },
    graphic: Results,
  },
]

interface Assessment {
  hasFilledAssessment?: boolean
  canEditAssessment?: boolean
}

interface BloodTimelineProps extends RouteComponentProps {
  assessment: Assessment
  details_link?: string
  report_id?: string
  status: BloodTestStatus
  test_id: string
  autoReload: any
  test_name: string
  test_type: TestType
  unconfirmed_result?: boolean
  calendly_status?: string
  outcome: string
  note: string
  attempt_type: string
  unconfirmed_result_message?: string
  consultation_status?: string
  archive_reason?: ErrorReason
  archive_reason_message?: string
  error_note?: any
}

// Timeline Item
function TimelineItem({
  anyStepFailed,
  currentStepIndex,
  hasFilledAssessment = false,
  canEditAssessment = false,
  unconfirmed_result,
  consultation_status,
  calendly_status,
  attempt_type,
  outcome,
  status,
  step_index,
  step,
  testId,
  error_note,
  errorReason,
  archive_reason,
}: {
  anyStepFailed: boolean
  currentStepIndex: number
  hasFilledAssessment: boolean
  canEditAssessment: boolean
  status: BloodTestStatus
  unconfirmed_result?: boolean
  consultation_status?: string
  calendly_status?: string
  attempt_type?: string
  outcome?: string
  step_index: number
  step: Step
  testId: string
  error_note: string
  errorReason?: ErrorReason
  archive_reason?: string
}) {
  // If step has function to check if it failed, run it
  let failed =
    step.failed &&
    step.failed(status, errorReason, consultation_status, calendly_status, attempt_type, outcome, archive_reason)
  let current = step.inProgress && step.inProgress(status, errorReason, consultation_status)
  let complete = step.isCompleted(status, errorReason, consultation_status)
  let label = step.label

  // Don't show Consultation required step unless test is unconfirmed SH test
  let display = step.isVisible(unconfirmed_result)
  if (label === 'Consultation Required' && !unconfirmed_result) {
    display = step.isVisible(unconfirmed_result)
  }

  // Show PhoneConsultation failed icon if the step fails
  if (step.label_before === 'Consultation Required' || step.label_before === 'Consultation Failed') {
    if (unconfirmed_result && failed) {
      step.graphic = ConsultationFailed
      step.label_before = 'Consultation Failed'
    } else {
      step.graphic = PhoneConsultation
      step.label_before = 'Consultation Required'
    }
  }

  let untestable

  // Handle weird assessment step (it's outside of normal flow)
  // If has filled assessment, then step is complete
  // If hasn't, mark as current (so it's nicely highlighted)
  if (step.name === 'assessment') {
    failed = !hasFilledAssessment
    current = !hasFilledAssessment
    complete = hasFilledAssessment

    // If assessment wasn't filled, change label to label_before
    if (failed && step.label_before) {
      label = step.label_before
    }
  }

  // To show status of timeline in red for untestable and inhibitory samples
  if (step.label === 'Analysed at Lab' && error_note) {
    untestable = 'error-untestable'
  }

  // If step wasn't completed and has "label_before", use it as a label
  // Used to show current state of step (like "Awaiting results")
  if (!complete && step.label_before) {
    label = step.label_before
  }

  // If it's current step and has link, replace test ID in it
  let link = current && step.link ? step.link.replace('{test_id}', testId) : null

  if ((step.name === 'assessment' && !canEditAssessment) || status === 'error') {
    link = null
  }

  const StepContainer = link ? Link : 'div'

  // Render timeline icon, depending on status
  const renderTimelineIcon = () => {
    if (failed) return <FontAwesomeIcon icon={faExclamation} />
    if (current) return <FontAwesomeIcon icon={faHourglass} />
    if (complete) return <FontAwesomeIcon icon={faCheck} />
  }

  return (
    <StepContainer
      to={link}
      className={`timeline__step ${!display && 'timeline__step--display'} ${current && 'timeline__step--current'} timeline__step--${error_note && untestable} ${failed && 'timeline__step--failed'} ${
        complete && 'timeline__step--complete'
      }`}
    >
      <div className="timeline__img-container">
        <img src={step.graphic} alt={label} className="timeline__img" />
        <div className="timeline__icon">{renderTimelineIcon()}</div>
      </div>
      <p className="timeline__title">{label}</p>
    </StepContainer>
  )
}

// Show appointment details
function AppointmentInfo({
  user_time,
  note,
  notes,
  meet_details,
  show_button,
  event_status,
  test_id,
  updatePostData,
  meetingDetails,
  handleEdit,
  displayCalendly,
}: {
  user_time: any
  note: any
  notes: any
  meet_details: any
  show_button: boolean
  event_status: string
  test_id: string
  updatePostData: any
  meetingDetails: any
  handleEdit: any
  displayCalendly: string
}) {
  return (
    <div>
      <p>
        Thank you for scheduling your appointment to speak with one of our doctors.
        <span className={event_status === 'reschedule_2' ? 'd-none' : ''}>
          In the event that you need to reschedule your appointment, we kindly request that you use the button below to
          do so within <strong>24 hours</strong> prior of your appointment.
        </span>
      </p>

      <h3>Appointment Details</h3>
      <>
        <strong>Date & Time: &nbsp; </strong>
        {user_time === null || meet_details === null ? <Spinner /> : user_time ? user_time : meet_details}
      </>
      <br />
      <p>
        <strong>Our doctor will contact you at the telephone number you have provided</strong>
      </p>

      {show_button === true
        ? event_status !== 'reschedule_2' && (
            <Button color="primary" className="m-2" onClick={() => handleEdit()}>
              Edit Appointment
            </Button>
          )
        : event_status !== 'reschedule_2' && (
            <Button color="primary" onClick={() => handleEdit()} disabled>
              Edit Appointment
            </Button>
          )}
      {(note || notes) && (
        <Alert className="m-2" color="warning">
          <ReactMarkdown>{notes ? notes : note}</ReactMarkdown>
        </Alert>
      )}
      <Container className={displayCalendly}>
        <Calendly
          id={test_id}
          event_status={event_status}
          displayCalendly={displayCalendly}
          updatePostData={updatePostData}
          meetingDetails={meetingDetails}
        />
      </Container>
      <br />
      <br />
    </div>
  )
}

// BloodTimeline component
var user_time: any
var dateObj: Date
var showAppointmentDetails: boolean = false
var appointmentMissed: boolean = false
function BloodTimeline({
  test_id,
  autoReload,
  test_name,
  test_type,
  status,
  details_link,
  report_id,
  assessment = {},
  unconfirmed_result,
  unconfirmed_result_message,
  calendly_status,
  outcome,
  note,
  attempt_type,
  consultation_status,
  archive_reason,
  archive_reason_message,
  error_note,
}: BloodTimelineProps) {
  const anyStepFailed = steps.some(step => step.failed && step.failed(status, archive_reason))
  const [displayCalendly, setdisplayCalendly] = useState(`timeline__step--display`)
  const [event_status, setEvent_status] = useState('')
  const [schedule_attempt, setSchedule_Attempt] = useState('')
  const [show_button, setShow_button] = useState(true)
  const [utcDateTime, setUtcDateTime] = useState('')
  const [meet_details, setMeet_details] = useState('')
  const [notes, setNotes] = useState('')
  const reportReady = status === 'report-ready'
  const reportLink = `/my-reports/blood/${report_id || ''}`
  const currentStep = steps.find(step => step.inProgress(status, archive_reason))
  const currentStepIndex = steps.findIndex(step => step === currentStep)
  let viewInstructionsUrl = 'https://myhealthchecked.com/help/videos/blood'
  const isSexualHealthTest = test_type?.indexOf('sex-health') !== -1
  if (isSexualHealthTest) {
    viewInstructionsUrl = 'https://myhealthchecked.com/help/videos/sexual-health'
  }

  useEffect(() => {
    const fetchUri = async () => {
      if (calendly_status !== null) {
        await window.app.requests.get(`/api/dashboard/calendly/appointment-details/${test_id}`).then((r: any) => {
          if (r.status === 200) {
            setEvent_status(r.data[0].status)
            setSchedule_Attempt(r.data[0].description)
            setUtcDateTime(r.data[0].start_time)
            meetingDetails()
          }
        })
      }
    }
    fetchUri()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [utcDateTime, user_time, meet_details, notes])

  const meetingDetails = (time?: any) => {
    if (time !== undefined || (utcDateTime !== undefined && utcDateTime !== '')) {
      dateObj = time ? new Date(time) : new Date(utcDateTime)

      const userDateTime = dateObj.toLocaleString(undefined, {
        weekday: 'long',
        day: 'numeric',
        month: 'long',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      })
      setMeet_details(userDateTime)
      // To disable Edit button if scheduled appointment time < 24 hrs.
      const _24BeforeMeeting = new Date(dateObj.getTime() - 24 * 60 * 60 * 1000)

      // Compare current time with 24 hours before the meeting
      const currentTime = new Date()
      if (currentTime < _24BeforeMeeting) {
        setShow_button(true)
      } else {
        setShow_button(false)
      }
      return userDateTime
    }
    setMeet_details('')
  }

  // To show appointment details when Calendly event is scheduled
  const toggleAppointmentDetails = useCallback(() => {
    if (calendly_status !== null && consultation_status !== 'consulted') {
      if (!(outcome === 'not_answered' || outcome === 'not_attended')) {
        showAppointmentDetails = true
      } else {
        showAppointmentDetails = false
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [schedule_attempt, attempt_type, outcome, calendly_status])

  // To show when user misses scheduled Calendly appointment
  const isAppointmentMissed = useCallback(() => {
    if (calendly_status !== null || calendly_status !== '') {
      if (outcome === 'not_answered' || outcome === 'not_attended') {
        appointmentMissed = true
      } else {
        appointmentMissed = false
      }
    } else return false

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calendly_status, schedule_attempt, attempt_type, outcome])

  // To update date & time when event gets rescheduled
  const updatePostData = useCallback(
    (attempts: any, start_time: any, description?: any, status?: any) => {
      if (calendly_status === null) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        calendly_status = 'scheduled'
        toggleAppointmentDetails()
        isAppointmentMissed()
      }
      setEvent_status(status)
      setSchedule_Attempt(description)
      setMeet_details(start_time)
      setNotes(attempts)
      setdisplayCalendly(`timeline__step--display`)
      appointmentMissed && autoReload()
    },
    [meet_details, event_status, schedule_attempt, displayCalendly, notes],
  )
  const handleEdit = async () => {
    setdisplayCalendly(`p-2`)
  }

  // Render instructions
  const renderInstructions = () => {
    let nextStep = steps[currentStepIndex] || {title: () => null}

    // If next step is 'assessment, skip to another step
    if (nextStep.name === 'assessment') {
      nextStep = steps[currentStepIndex + 2] || {title: () => null}
    }

    // If it is Menopause or PCOS test AND assessment wasn't filled
    // AND still can be filled - then show warning
    if (
      (test_type === 'blood-menopause' || test_type === 'blood-polycystic') &&
      !assessment.hasFilledAssessment &&
      assessment.canEditAssessment
    )
      return (
        <div>
          <p className="text-justify">
            Our records show that we have not received your completed Health Assessment for{' '}
            <strong>{test_name}</strong>, and without this, you will not receive your results.
          </p>

          <p className="text-justify">
            The information you submit will provide health and lifestyle context for the doctor who reviews your
            results. We will also ask you questions about your menstrual cycle because blood results can depend on the
            phase of a woman’s cycle.
          </p>
        </div>
      )

    if (!nextStep.instructions) return null

    return nextStep.instructions({
      test_id,
      viewInstructionsUrl,
      filledAssessment: !!assessment.hasFilledAssessment,
      errorReason: archive_reason,
      errorReasonMessage: archive_reason_message,
      consultation_status,
    })
  }

  toggleAppointmentDetails()
  isAppointmentMissed()
  return (
    <div className="my-3 border-0">
      <h2 className="text-center text-md-left">{test_name}</h2>
      <div className="text-center text-md-left text-muted">
        {/* Show different UI messages based on status of Calendly appointment */}
        {/* Show different Phone Consultation UI messages if test results turn out unconfirmed reactive and no Calendly appointment is scheduled*/}
        {isSexualHealthTest &&
          unconfirmed_result &&
          !archive_reason &&
          (calendly_status === null && showAppointmentDetails !== true ? (
            <>
              <Alert color="danger" fade={false}>
                <h1>Phone Consultation Required</h1>
                <ReactMarkdown>{unconfirmed_result_message ? unconfirmed_result_message : ''}</ReactMarkdown>
              </Alert>
              {(note || notes) && (
                <Alert color="warning">
                  <ReactMarkdown>{notes ? notes : note}</ReactMarkdown>
                </Alert>
              )}
              <Container className="p-2">
                <Calendly
                  id={test_id}
                  event_status={event_status}
                  updatePostData={updatePostData}
                  meetingDetails={meetingDetails}
                />
              </Container>
            </>
          ) : appointmentMissed !== true && showAppointmentDetails === true ? (
            <>
              {user_time || meet_details ? (
                <AppointmentInfo
                  user_time={user_time}
                  meet_details={meet_details}
                  show_button={show_button}
                  event_status={event_status}
                  test_id={test_id}
                  note={note}
                  notes={notes}
                  updatePostData={updatePostData}
                  meetingDetails={meetingDetails}
                  handleEdit={handleEdit}
                  displayCalendly={displayCalendly}
                />
              ) : (
                <Loading />
              )}
            </>
          ) : appointmentMissed ? (
            <>
              <Alert color="danger" fade={false}>
                <h1>You Missed Your Appointment!</h1>
                <ReactMarkdown children={unconfirmed_result_message ? unconfirmed_result_message : ''} />
              </Alert>
              {(note || notes) && (
                <Alert className="mt-2" color="warning">
                  <ReactMarkdown>{notes ? notes : note}</ReactMarkdown>
                </Alert>
              )}
              {schedule_attempt !== 'phone_3' && (
                <Container className="p-2">
                  <Calendly
                    id={test_id}
                    updatePostData={updatePostData}
                    event_status={event_status}
                    meetingDetails={meetingDetails}
                  />
                </Container>
              )}
            </>
          ) : (
            renderInstructions()
          ))}
        {/* If status = 'error' and there is archive_reason_message, show only error
            without timeline */}
          {archive_reason_message ? (
              (
                (error_note && archive_reason_message) || error_note ? (
                  <Alert color="danger" fade={false}>
                    <ReactMarkdown>{error_note}</ReactMarkdown>
                  </Alert>
                ) : archive_reason_message ? (
                  <Alert color="danger" fade={false}>
                    <ReactMarkdown>{archive_reason_message}</ReactMarkdown>
                  </Alert>
                ) : (
                  renderInstructions()
                )
              )
            ) : null}


        <div className="mb-4">
          {!!reportReady && (
            <p>Our doctor has reviewed your results. Your report has been published and is ready to view.</p>
          )}
          <div className="d-flex">
            {!!reportReady && (
              <Button className="mr-3" tag={Link} to={reportLink} color="primary">
                View Report
              </Button>
            )}
            {/* If has assessment, show button to view it */}
            {assessment.hasFilledAssessment && !assessment.canEditAssessment && (
              <Button tag={Link} to={`/assessment/${test_id}`} color="primary" outline>
                View Health Assessment
              </Button>
            )}
          </div>
        </div>
      </div>

      {!!details_link && (
        <div className="mt-2">
          <Link to={`/tests/${test_id}/`}>View {status === 'report-ready' ? 'report' : 'test details'}</Link>
        </div>
      )}

      <section className="timeline">
        {steps.map((step, i) => (
          <TimelineItem
            key={i}
            step={step}
            step_index={i}
            hasFilledAssessment={!!assessment.hasFilledAssessment}
            canEditAssessment={!!assessment.canEditAssessment}
            unconfirmed_result={unconfirmed_result}
            consultation_status={consultation_status}
            calendly_status={calendly_status}
            attempt_type={attempt_type}
            outcome={outcome}
            currentStepIndex={currentStepIndex}
            anyStepFailed={anyStepFailed}
            testId={test_id}
            status={status}
            error_note={error_note}
            errorReason={archive_reason}
            archive_reason={archive_reason}
          />
        ))}
      </section>

      {/* Show unfilled assessment message if needed */}
      <AssessmentMessage assessment={assessment} status={status} test_id={test_id} />
    </div>
  )
}

export default withRouter(BloodTimeline)

interface AssessmentMessageProps {
  assessment: Assessment
  status: BloodTestStatus
  test_id: string
}

// Assessment message - show if assessment can be edited
function AssessmentMessage({assessment, test_id}: AssessmentMessageProps) {
  // Show message only when assessment can be edited
  if (!assessment.canEditAssessment) return null

  // If filled assessment, show edit message
  if (assessment.hasFilledAssessment) {
    return (
      <>
        <Alert className="mt-3" color="info" fade={false}>
          Feel free to edit your Health Assessment to provide the most accurate details for our doctor.
          <br />
          <Button tag={Link} to={`/assessment/${test_id}`} color="info" className="mt-2" size="sm">
            Edit Assessment
          </Button>
        </Alert>
      </>
    )
  }

  // Otherwise - there are no assessment yet
  return (
    <>
      <Alert className="mt-3" color="warning" fade={false}>
        Please{' '}
        <strong>
          <Link to={`/assessment/${test_id}/1`} className="alert-link">
            complete your Health Assessment
          </Link>
        </strong>
        . Our doctor cannot review your results effectively without this information.
        <br />
        <Button tag={Link} to={`/assessment/${test_id}/1`} color="warning " className="mt-2" size="sm">
          Fill Assessment
        </Button>
      </Alert>
    </>
  )
}
