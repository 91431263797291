import React from 'react'
import {Link} from 'react-router-dom'
import {Button} from 'reactstrap'

interface LinkCardProps {
  button_text?: string
  button_append_class?: string
  button2_link?: string
  button2_text?: string
  button2_append_class?: string
  button3_link?: string
  button3_text?: string
  button3_append_class?: string
  button4_text?: string
  button4_link?: string
  button4_target?: string
  children: any
  image: string
  title: string
  to: string
  ts?: string | number
}

// LinkCard
export function LinkCard({
  to,
  image,
  ts: date,
  title,
  button_text,
  button_append_class,
  children,
  button2_text,
  button2_link,
  button2_append_class,
  button3_text,
  button3_link,
  button3_append_class,
  button4_text,
  button4_link,
  button4_target,
}: LinkCardProps) {
  const ContainerTag = button2_link ? 'div' : CleverLink
  return (
    <ContainerTag to={to} className="text-decoration-none text-reset">
      <div className="bg-white rounded d-flex flex-nowrap no-gutters mb-4 padded-shadow-card shadow raise">
        <div className="d-flex pr-2 col-3 mw-100px">
          <img className="img-fluid can-mute p-2" src={image} alt={title} width={100} height={100} />
        </div>
        <div className="link-title">
          {date ? <div className="text-muted mb-1">{date}</div> : null}
          <h5 className="mb-2">
            <strong>{title}</strong>
          </h5>
          {children}
          <Button tag={Link} to={to} color="primary" className={'d-md-none mt-2 ' + button_append_class}>
            {button_text || title}
          </Button>
          {button2_text && (
            <Button
              tag={Link}
              color="primary"
              to={button2_link}
              className={'report-btn d-md-none standard-button mt-2 ' + button2_append_class}
              outline
            >
              {button2_text}
            </Button>
          )}
          {button3_text && (
            <Button
              tag={Link}
              to={button3_link}
              color="primary"
              className={'report-btn d-md-none standard-button mt-2 ' + button3_append_class}
              outline
            >
              {button3_text}
            </Button>
          )}
          {button4_text && (
            <Button
              onClick={e => {
                e.stopPropagation()
                console.log(button4_link)
              }}
              tag="a"
              href={button4_link}
              color="primary"
              className="report-btn d-md-none standard-button mt-2 " // you can change this to whatever suits your style
              outline
              target="_blank" // since it's external only
              rel="noopener noreferrer"
              style={{minWidth: '160px'}}
            >
              {button4_text}
            </Button>
          )}
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '.5rem',
            justifyContent: 'center',
            marginRight: '40px',
          }}
        >
          {(to.startsWith('http') && (
            <Button
              to={to}
              className="d-none d-md-block mt-2 "
              color={'primary ' + button_append_class}
              style={{minWidth: '160px'}}
            >
              {button_text || title}
            </Button>
          )) || (
            <Button
              tag={Link}
              to={to}
              className="d-none d-md-block mt-2 "
              color={'primary ' + button_append_class}
              style={{minWidth: '160px'}}
            >
              {button_text || title}
            </Button>
          )}

          {button2_text &&
            (button2_text !== 'Return to My Activation' && button2_append_class === 'disabled' ? (
              <Button
                disabled
                className={
                  'd-none d-md-block mt-2 ' + (button2_text === 'Return to My Activation' ? '' : button2_append_class)
                }
                color="primary"
                outline
                style={{minWidth: '160px'}}
              >
                {button2_text}
              </Button>
            ) : (
              <Button
                tag={Link}
                to={button2_link}
                className={
                  'd-none d-md-block mt-2 ' + (button2_text === 'Return to My Activation' ? '' : button2_append_class)
                }
                color="primary"
                outline
                style={{minWidth: '160px'}}
              >
                {button2_text}
              </Button>
            ))}
          {button3_text &&
            (button3_append_class === 'disabled' ? (
              <Button
                disabled
                className={'d-none d-md-block mt-2 ' + button3_append_class}
                color="primary"
                outline
                style={{minWidth: '160px'}}
              >
                {button3_text}
              </Button>
            ) : (
              <Button
                tag={Link}
                to={button3_link}
                className={'d-none d-md-block mt-2 ' + button3_append_class}
                color="primary"
                outline
                style={{minWidth: '160px'}}
              >
                {button3_text}
              </Button>
            ))}

          {button4_text && (
            <Button
              onClick={e => {
                e.stopPropagation()
                console.log(button4_link)
              }}
              tag="a"
              href={button4_link}
              className="d-none d-md-block standard-button mt-2 "
              color="primary" // you can change this to whatever suits your style
              outline
              target="_blank" // since it's external only
              rel="noopener noreferrer"
              style={{minWidth: '160px'}}
            >
              {button4_text}
            </Button>
          )}
        </div>
      </div>
    </ContainerTag>
  )
}

interface CleverLinkProps {
  to: string
  className?: string
  children: any
}

// Clever link - If passed external link, use <a /> tag.
// If passed relative, internal link - use <Link /> to navigate
function CleverLink({to, className = '', children}: CleverLinkProps) {
  if (to.startsWith('http')) {
    return (
      <a href={to} className={className} target="_blank" rel="noopener noreferrer">
        {children}
      </a>
    )
  } else {
    return (
      <Link to={to} className={className}>
        {children}
      </Link>
    )
  }
}
