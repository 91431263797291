import React from 'react'
import moment from 'moment'

export async function prepareCountries() {
  const countries = await getCountries()
  window.country_lookup = Object.fromEntries(countries.map(c => [c.code, c.name]))
  window.country_choices = countries.map(c => ({value: c.code, label: c.name}))
}

async function getCountries() {
  const cache_key = 'countries-' + moment().format('YYYY-MM-DD')
  const countries_json = localStorage[cache_key]
  if (countries_json) {
    // clear up old countries-* keys
    Object.keys(localStorage)
      .filter(k => k.startsWith('countries-') && k !== cache_key)
      .forEach(k => localStorage.removeItem(k))

    return JSON.parse(countries_json)
  }
  const r = await window.app.requests.get(`/api/countries/`)
  localStorage[cache_key] = JSON.stringify(r.data)
  return r.data
}

export function buildFormFields(fields_def) {
  if (Array.isArray(fields_def)) {
    fields_def = Object.fromEntries(fields_def.map(f => [f, null]))
  }
  return Object.fromEntries(
    Object.entries(fields_def).map(([k, v]) => {
      const field_ = all_fields[k]
      if (!field_) {
        throw new Error(`Field ${k} not found in all fields`)
      }
      const field = {...field_}
      if (field.type === 'countries') {
        field.type = 'select'
        if (!window.country_choices) {
          throw new Error(`window.country_choices not yet prepared`)
        }
        field.choices = window.country_choices || []
      }
      if (typeof v === 'boolean') {
        field.required = v
      } else if (typeof v === 'object') {
        Object.assign(field, v)
      }
      return [k, field]
    }),
  )
}

// NOTE! some of these fields are used both on forms AND to display details, hence mashup of properties
const all_fields = {
  // User fields
  email: {type: 'email', max_length: 127},
  first_name: {
    max_length: 63,
    help_text: (
      <>
        This must be entered <b>EXACTLY</b> as it appears on your passport.
      </>
    ),
  },
  last_name: {
    max_length: 63,
    help_text: (
      <>
        This must be entered <b>EXACTLY</b> as it appears on your passport.
      </>
    ),
  },
  phone_number_primary: {
    title: 'Main Phone Number',
    type: 'phone',
    max_length: 30,
    placeholder: 'Enter a valid UK phone number',
  },
  phone_number_secondary: {title: 'Alternative Phone Number', type: 'tel', max_length: 30},
  home_street_address: {min_length: 1, max_length: 127},
  home_city: {max_length: 30},
  home_postal_code: {min_length: 1, max_length: 127},
  uk_street_address: {min_length: 1, max_length: 127},
  uk_city: {max_length: 30},
  uk_postal_code: {min_length: 1, max_length: 127},
  dob: {
    title: 'Date of Birth',
    fmt: 'date',
    type: 'date',
    help_text: 'This must match the data on your passport.',
    // the min age is overwritten based on the test type
    min: '1900-01-01',
  },
  passport_number: {
    fmt: 'inline-code',
    autocomplete: 'off',
    min_length: 5,
    max_length: 15,
  },
  nhs_number: {
    fmt: 'inline-code',
    title: 'NHS Number',
    help_text: 'UK NHS number where available.',
    autocomplete: 'off',
    min_length: 7,
    max_length: 15,
    extra: {pattern: '[ \\d]+'},
  },
  sex: {
    title: 'Gender at Birth',
    type: 'toggle',
    choices: [
      {value: 'female', label: 'Female'},
      {value: 'male', label: 'Male'},
      {value: 'pnts', label: 'Prefer not to say'},
    ],
    help_text: 'Required for Public Health England reporting.',
  },
  identify_with_gender: {
    title: 'Do you still identify with this gender?',
    type: 'toggle',
    choices: [
      {value: 'true', label: 'Yes'},
      {value: 'false', label: 'No'},
    ],
    help_text: 'We ask of this so that we can provide the optimal results.',
  },
  ethnicity: {
    type: 'radio',
    choices: [
      {value: 'white', label: 'White'},
      {value: 'asian', label: 'Asian'},
      {value: 'black', label: 'Black, African, Caribbean or Black British'},
      {value: 'mixed', label: 'Mixed ethnic group'},
      {value: 'other', label: 'Other ethnic group'},
    ],
  },

  // Test fields
  secondary_barcode: {
    title: 'Secondary Barcode',
    type: 'mask',
    help_text: '10 character code printed on the sticker.',
    max_length: 12,
    autocomplete: 'off',
    custom_type: 'text',
    extra: {
      mask: [/[a-zA-Z]/, /[a-zA-Z]/, /[a-zA-Z]/, '-', /[a-zA-Z]/, /[a-zA-Z]/, /[a-zA-Z]/, '-', /\d/, /\d/, /\d/, /\d/],
      pattern: `[a-zA-Z]{3}-[a-zA-Z]{3}-[0-9]{4}`,
      title: 'Barcode should be in format ABC-abc-1234',
    },
  },
  test_ts: {
    title: 'Date & Time of Test Taken',
    fmt: 'datetime',
    type: 'datetime',
    // set min to 2 weeks ago
    min: new Date(Date.now() - 336 * 60 * 60 * 1000).toISOString().split('T')[0],
    // set max to 60 minutes from now (this returns what the date is in 60 minutes, which is today's date until after 11pm)
    max: new Date(Date.now() + 60 * 60 * 1000).toISOString().split('T')[0],
    help_text:
      'Please enter the date and time that you performed your test; or the time you intend to take it within the next 60 minutes',
  },
  departure_ts: {title: 'Departure Date & Time', fmt: 'datetime', type: 'datetime'},
  arrival_ts: {title: 'Arrival Date & Time', fmt: 'datetime', type: 'datetime'},

  departure_country_status: {fmt: 'as-title', type: 'select', choices: ['green', 'amber-exempt', 'amber-non-exempt']},
  home_country: {fmt: 'as-country', type: 'countries'}, // this gets replaced in buildForm
  departure_country: {fmt: 'as-country', type: 'countries'}, // this gets replaced in buildForm
  destination_country: {fmt: 'as-country', type: 'countries'}, // this gets replaced in buildForm
  transit_country: {fmt: 'as-country', type: 'countries'}, // this gets replaced in buildForm

  travel_method: {fmt: 'as-title', type: 'select', choices: ['flight', 'train', 'ferry']},
  travel_vehicle: {fmt: 'as-title', type: 'select', choices: ['walk-on', 'car', 'motorbike', 'coach']},
  travel_number: {fmt: 'inline-code'},
  symptoms: {
    type: 'checkboxes',
    help_text:
      'Have you recently, or are you currently experiencing any of these symptoms? Please check all that apply.',
    choices: [
      {value: 'high-temp', label: 'High temperature'},
      {value: 'cough', label: 'A new, persistent cough'},
      {value: 'breathing', label: 'Breathing difficulties'},
      {value: 'loss-taste', label: 'Loss of taste or smell'},
      {value: 'fever', label: 'Fever'},
      {value: 'chills', label: 'Chills'},
      {value: 'aches', label: 'Body aches or muscle pain'},
    ],
  },
  postage_tracking: {
    title: 'Postage Tracking Number',
    max_length: 63,
    help_text:
      "This is the tracking number from your return envelope, it's not required but can help in the event your kit goes missing.",
  },
  vendor: {type: 'select', title: 'Where was your kit purchased?', choices: ['boots', 'other']},
  marketing_consent: {
    type: 'bool',
    help_text:
      "Tick this box to receive emails with news and offers from MyHealthChecked. We won't share your personal data and you can unsubscribe at any time.",
  },
  vaccination_status: {
    title: 'COVID-19 Vaccination Status',
    type: 'select',
    choices: ['none', 'partial', 'full'],
    help_text: 'Required for Public Health England reporting',
  },
  home_address: {},
  uk_address: {
    title: 'UK Address',
    help_text: 'Address in the UK where you will be able to receive a confirmatory test',
  },
  booking_reference: {
    required: false,
    title: 'Booking Reference (required)',
    max_length: 18,
    autocomplete: 'off',
    extra: {
      bsSize: 'lg',
    },
    help_text: 'Booking reference should be a 12 character code, you will have received via email after purchase.',
  },
}
