import React, {useEffect, useState} from 'react'
import {Row, Col} from 'reactstrap'

import {LinkCard} from 'src/shared/LinkCard'
import Test from 'src/assets/icons/Platform/Buy_tests.svg'
import Pink from 'src/assets/icons/pink_marketing.svg'
import ReportHighlightsSection from './Reports/ReportHighlightsSection'
import GeneralTimeline from 'src/views/TestDetails/GeneralTimeline'
import WellnessTimeline from 'src/views/TestDetails/WellnessTimeline'
import BloodTimeline from 'src/views/TestDetails/BloodTimeline'
import {Error, Loading} from 'src/shared/reactstrap-toolbox'

var id

//Dashboard Index
export default ({user, bodyData}) => {
  const [error, setError] = React.useState(null)
  const [loadingReports, setLoadingReports] = useState(true)
  const [loadingTests, setLoadingTests] = useState(true)
  const [latestReport, setLatestReport] = useState(null)
  const [progressStep, setProgressStep] = useState(null)
  const [test, setTest] = useState(null)
  const [reload, setReload] = useState(false)
  const [fromChild, setFromChild] = useState(false)

  const autoReload = () => {
    setReload(!reload)
    setFromChild(true)
  }

  useEffect(() => {
    window.app.setTitle('Dashboard')
    id &&
      window.app.requests.get(`/api/dashboard/reports/latest/${id}`).then(r => {
        if (r.data && r.data.id !== 0) {
          setLatestReport(r.data)
          setLoadingReports(false)
        } else {
          window.app.requests.get('/api/dashboard/summary/').then(r => {
            if (r.data.latest_test) {
              setProgressStep(r.data.latest_test.status)
            } else {
              setProgressStep(null)
            }
            setLoadingReports(false)
          })
        }
      })
    setLoadingReports(false)
  }, [test])
  useEffect(() => {
    !fromChild && setTest(null)
    window.app.requests
      .get(`/api/dashboard/tests/`, null, {expected_status: [200, 404]})
      .then(r => {
        if (r.status === 200 && r.data.tests && r.data.tests.length > 0) {
          setTest(r.data.tests[0])
          id = r.data.tests[0].id
        } else {
          setTest(null)
        }
        setLoadingTests(false)
      })
      .catch(error => {
        setError(`Error fetching tests: ${error.message}`)
        setLoadingTests(false)
      })
    // eslint-disable-next-line
  }, [reload])

  if (loadingReports || loadingTests) {
    return <Loading />
  }

  if (error) {
    return <Error error={error} />
  }

  return (
    <div>
      <div className="pt-sm-4 pl-sm-1 pt-md-2 ml-md-3">
        <Summary
          latestReport={latestReport}
          progressStep={progressStep}
          user={user}
          test={test}
          autoReload={autoReload}
        />
      </div>
    </div>
  )
}

const Summary = ({latestReport, progressStep, user, test, autoReload}) => {
  const Placeholder = () => {
    return (
      <div>
        <h1>Welcome {user.name}</h1>
        <p>Welcome to your personalised MyHealthChecked panel.</p>
        <p>
          Once you've completed your first test, you'll receive personalised advice and support to improve your health.
        </p>
        <div className="button-group">
          <LinkCard to="https://myhealthchecked.com/our-tests" image={Test} title="Buy tests" button_text="Buy now">
            <div className="text-muted">Get started today by buying a wellness test with us!.</div>
          </LinkCard>
        </div>
        <div className="button-group">
          <LinkCard to="/activate" image={Pink} title="Activate test" button_text="Activate">
            <div className="text-muted">Already have a test and need to get it activated?.</div>
          </LinkCard>
        </div>
      </div>
    )
  }

  // Grab the case where we have nothing
  if (
    (latestReport === null || latestReport === undefined) &&
    (progressStep === null || progressStep === undefined) &&
    (test === null || test === undefined)
  ) {
    return <Placeholder />
  }

  // Pull out the wellness flag
  var isLastTestWellness = false
  var isLastTestBlood = false
  var showWellnessReport = false
  // Check the test
  if (null !== test && undefined !== test) {
    if (null !== test.type && undefined !== test.type) {
      isLastTestWellness = test.type.startsWith('wellness-')
      isLastTestBlood = test.type.startsWith('blood-')

      // Now check the report
      if (latestReport !== null && undefined !== latestReport) {
        if (isLastTestWellness && test.report_id !== null) {
          var lastTestTime = new Date(test.status_ts)
          var lastReportTime = new Date(latestReport.timestamp)
          showWellnessReport = lastTestTime <= lastReportTime
        } else {
          showWellnessReport = false
        }
      }
    }
  }

  // Then build the main area
  var main_area = <Placeholder />

  if (latestReport && test && isLastTestWellness && test.status.includes('results-ready')) {
    // Everything we need for a wellness report
    main_area = <ReportHighlightsSection latestReport={latestReport} />
  } else if (latestReport && test && showWellnessReport) {
    // Show an updated report even if it's older than the latest test
    main_area = <ReportHighlightsSection latestReport={latestReport} />
  } else if (test && isLastTestWellness) {
    // Wellness test not ready for a report
    main_area = (
      <WellnessTimeline test_id={test.id} status={test.status} test_type={test.type} test_name={test.type_name} />
    )
  } else if (test && isLastTestBlood) {
    // Blood test not ready for a report
    main_area = (
      <BloodTimeline
        test_id={test.id}
        autoReload={autoReload}
        status={test.status}
        test_name={test.type_name}
        test_type={test.test_kit_type}
        report_id={test.report_id}
        assessment={{
          hasFilledAssessment: test.assessment_id,
          canEditAssessment: test.can_edit_assessment,
        }}
        unconfirmed_result={test.unconfirmed_result}
        unconfirmed_result_message={test.unconfirmed_result_message}
        calendly_status={test.calendly_status}
        outcome={test.outcome}
        note={test.note}
        attempt_type={test.attempt_type}
        consultation_status={test.consultation_status}
        archive_reason={test.archive_reason}
        archive_reason_message={test.archive_reason_message}
        error_note={test.error_note}
      />
    )
  } else if (test && !isLastTestWellness && !isLastTestBlood) {
    // Other test not ready for a report
    main_area = (
      <GeneralTimeline test_id={test.id} status={test.status} test_type={test.type} test_name={test.type_name} />
    )
  } else {
    // Fall back to the place holder
    main_area = <Placeholder />
  }

  return (
    <div className="pt-2 pt-md-5 px-md-5">
      <Row>
        <Col>
          <div className="mb-4 pt-4">
            <h1 className="text-center text-md-left">Welcome {user.first_name}!</h1>
          </div>
          <div className="w-md-75 mb-4 pr-md-5 mr-md-5">
            <p className="text-center text-md-left">Welcome to your personalised MyHealthChecked panel.</p>
          </div>
          {main_area}
        </Col>
      </Row>
    </div>
  )
}
