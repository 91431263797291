import React from 'react'
import {withRouter} from 'react-router-dom'
import * as Sentry from '@sentry/react'
import {Requests} from 'src/shared/reactstrap-toolbox'
import ReactGA from 'react-ga4'
import {prepareCountries} from './form_fields'
import Page from './Page'
import {hasAnalyticsCookie} from './shared/useAcceptCookies'

class App extends React.Component {
  state = {
    error: null,
    authenticate_sentinel: 0,
    reactGAInitialized: false,
    isLoggedIn: !!sessionStorage.getItem('isLoggedIn') || false,
  }

  constructor(props) {
    super(props)
    // _history is used in componentDidUpdate to correctly set window.last_path on "back"
    this._history = []
    window.app = {
      setError: error => this.setError(error),
      reAuthenticate: () => this.setState(s => ({authenticate_sentinel: s.authenticate_sentinel + 1})),
      requests: new Requests({on_error: e => this.setError(e)}),
      // done like this so the app can use title in future
      setTitle: title => {
        document.title = title || 'MyHealthChecked Dashboard'
      },
    }
    prepareCountries()
  }

  initializeGA = () => {
    if (true === hasAnalyticsCookie() && false === this.state.reactGAInitialized) {
      const ga_id = process.env.REACT_APP_ENVIRONMENT === 'production' ? 'G-S2LWN36ZZH' : 'G-J8B9QY4LEQ'
      ReactGA.initialize(ga_id, {gaOptions: {allowLinker: true}})
      this.setState({reactGAInitialized: true})
    }
  }

  pageview = () => {
    if (hasAnalyticsCookie()) {
      ReactGA.send('pageview')
    }
  }

  componentDidMount() {
    this.initializeGA()
    this.pageview()
    window.app.setTitle()

    if (this.state.isLoggedIn) {
      window.app.requests
        .post('/api/dashboard/logout/')
        .then(() => {
          sessionStorage.clear()
        })
        .catch(error => {
          console.error('Logout failed:', error)
        })
    }
  }

  full_path = loc => `${loc.pathname}${loc.search}${loc.hash}`

  componentDidUpdate(prevProps) {
    this.initializeGA()

    const prev_full_path = this.full_path(prevProps.location)
    if (this.full_path(this.props.location) !== prev_full_path) {
      // window.last_path is used by utils/back_or_forward decide whether to use history.goBack() or history.push(...)
      if (this.props.history.action === 'POP') {
        window.last_path = this._history.pop() || null
      } else {
        this._history.push(window.last_path)
        window.last_path = prev_full_path
      }
      // if you navigate away from an error page, the error show disappear
      this.state.error && this.setState({error: null})
      this.pageview()
    }
  }

  componentDidCatch(error, info) {
    Sentry.captureException(error, {extra: info})
    this.setState({error})
  }

  setError = error => {
    if (error.details?.status === 401) {
      // session has expired, or not authenticated, point the user to login
      window.app.reAuthenticate()
    } else {
      Sentry.captureException(error)
      console.log('settings error:', error, '\ndetails:', error.details)
      this.setState({error})
    }
  }

  render() {
    return <Page {...this.state} />
  }
}

export default withRouter(App)
