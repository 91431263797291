import React from 'react'
import {Redirect, useLocation} from 'react-router-dom'
import {get_component_name} from 'src/shared/reactstrap-toolbox'
import {Col} from 'reactstrap'

const dont_next = ['/', '/logout/']

const RedirectAnon = () => {
  const pathname = useLocation().pathname
  // user is not authenticated but the current path requires authentication, redirect to login, possibly
  // with next provided to redirect them
  if (dont_next.includes(pathname)) {
    return <Redirect to="/login/" push={false} />
  } else {
    return <Redirect to={`/login/?next=${encodeURIComponent(pathname)}`} push={false} />
  }
}

const PageContent = (width, Component, props, auth_user) => {
  // this component takes care of the width of the main content, and also redirecting if the view requires
  // an authenticated user
  const PageComponent = extra_props => {
    if (!auth_user) {
      return <RedirectAnon />
    } else if (width === 'Narrow') {
      return (
        <Col /*lg={9} xl={10}*/>
          <div>
            <Component {...props} {...extra_props} />
          </div>
        </Col>
      )
    } else if (width === 'Wide') {
      return (
        <Col /*lg={9} xl={10}*/>
          <Component {...props} {...extra_props} />
        </Col>
      )
    } else {
      throw new Error(`unknown page width "${width}", must be "Narrow" or "Wide"`)
    }
  }
  PageComponent.displayName = `${width}(${get_component_name(Component)})`
  return PageComponent
}

// auth_user true to skip redirection and allow the pages to work for anonymous users
export const Narrow = (Component, props = {}, auth_user = true) => PageContent('Narrow', Component, props, auth_user)
export const Wide = (Component, props = {}, auth_user = true) => PageContent('Wide', Component, props, auth_user)
