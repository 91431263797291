import React from 'react'
import {ListGroup, ListGroupItem as BsListGroupItem, Row, Col, Collapse, Navbar, NavbarToggler} from 'reactstrap'
import {Link, useLocation} from 'react-router-dom'
import {Button} from 'reactstrap'
import {as_title} from 'src/shared/reactstrap-toolbox'
import logo from 'src/assets/icons/mhc_logo.svg'
import {ReactComponent as dashboard} from 'src/assets/icons/Dashboard.svg'
import {ReactComponent as results} from 'src/assets/icons/Results.svg'
import {ReactComponent as settings} from 'src/assets/icons/Settings.svg'
import {ReactComponent as assessment} from 'src/assets/icons/notepad.svg'
import {ReactComponent as support} from 'src/assets/icons/support.svg'

const ListGroupItem = ({to, active, title, icon}) => {
  const Icon = icon

  const isExternalLink = to.startsWith('http') || to.startsWith('https')

  return (
    <BsListGroupItem
      tag={isExternalLink ? 'a' : Link}
      href={isExternalLink ? to : undefined}
      to={!isExternalLink ? to : undefined}
      active={active}
      target={isExternalLink ? '_blank' : undefined}
      rel={isExternalLink ? 'noopener noreferrer' : undefined}
    >
      {icon ? <Icon width={25} height={25} className="float-left p-1 mr-2" /> : null}
      <div className="text-light">{title}</div>
    </BsListGroupItem>
  )
}

const auth_menu = [
  {to: '/', name: 'Dashboard', icon: dashboard},
  {to: '/activate', name: 'Activate Test', icon: assessment},
  {to: '/tests', name: 'My Results', icon: results},
  {to: '/account', name: 'Account', icon: settings},
  {to: 'https://myhealthchecked.com/faq/blood-analysis-test', name: 'Support', icon: support},
]

export default ({className, user}) => {
  const location = useLocation()
  let menu_ = user ? auth_menu : []
  const [isOpen, setIsOpen] = React.useState(false)
  const path = location.pathname

  React.useEffect(() => {
    setIsOpen(false)
  }, [path])

  const toggle = () => setIsOpen(!isOpen)

  return (
    <Col className={`sidebar-background p-0 m-0 overflow-hidden sticky-top`}>
      <Row>
        <Col xs={2} md={1}>
          <div className="d-lg-none p-3">
            <NavbarToggler className="navbar-light" onClick={toggle} />
          </div>
        </Col>
        <Col>
          <Link to="/" className="">
            <img width={135} className="p-2 m-2" src={logo} alt="My Health Checked" />
          </Link>
        </Col>
      </Row>
      <Navbar expand="lg" className={'p-0'}>
        <Col className="p-0 m-0">
          <Collapse className="d-lg-none" isOpen={isOpen}>
            <div className="sidebar-background">
              <ListGroup className="menu text-nowrap">
                {menu_.map(m => (
                  <ListGroupItem key={m.to} to={m.to} active={m.to === path} icon={m.icon} title={as_title(m.name)} />
                ))}

                {user ? (
                  <Button tag={Link} to="/logout/" className="logout-button">
                    Log out
                  </Button>
                ) : null}
              </ListGroup>
            </div>
          </Collapse>

          <div className="d-none d-lg-block">
            <ListGroup className="menu text-nowrap">
              {menu_.map(m => (
                <ListGroupItem key={m.to} to={m.to} active={m.to === path} icon={m.icon} title={as_title(m.name)} />
              ))}

              {user ? (
                <Button tag={Link} to="/logout/" outline className="logout-button">
                  Log out
                </Button>
              ) : null}
            </ListGroup>
          </div>
        </Col>
      </Navbar>
    </Col>
  )
}
