import {ComponentClass, FC} from 'react'

// usage [1, 2, 3, 2, 4].filter(filter_unique) > [1, 2, 3, 4]
export const filter_unique = (value: any, index: number, array: any[]): boolean => array.indexOf(value) === index

// usage await sleep(1000)
export const sleep = (ms: number): Promise<(...args: any) => any> => new Promise(resolve => setTimeout(resolve, ms))

// usage as_title('what_ever') > 'What Ever'
export const as_title = (s: string): string =>
  (s || '')
    .replace(/(_|-)/g, ' ')
    .replace(/(_|\b)\w/g, l => l.toUpperCase())
    .replace(/\bdna\b/gi, 'DNA')

export const get_component_name = (Comp: FC | ComponentClass) => Comp.displayName || Comp.name || 'Component'

export const on_mobile = /mobile|ip(hone|od|ad)|android|blackberry|opera mini/i.test(navigator.userAgent)

class _DetailedError extends Error {
  name = 'DetailedError'
  details?: any
  constructor(message: string, details?: any) {
    super()
    this.message = message
    this.details = details
  }
}

export const DetailedError = (message: string, details?: any) => new _DetailedError(message, details)

// usage await load_script('https://www.example.com/script.js')
export function load_script(url: string) {
  return new Promise<void>((resolve, reject) => {
    if (document.querySelector(`script[src="${url}"]`)) {
      // script already loaded
      resolve()
    } else {
      const script = document.createElement('script')
      script.src = url
      script.onerror = e => reject(e)
      script.onload = () => resolve()
      document.body.appendChild(script)
      setTimeout(() => reject(`script "${url}" timed out`), 8000)
    }
  })
}

export const combine_classes = (...classes: string[]) => classes.filter(c => c).join(' ')
