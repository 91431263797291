import React, {useEffect} from 'react'
import {Redirect} from 'react-router-dom'
import {faCheck, faExclamationTriangle} from '@fortawesome/free-solid-svg-icons'
import {Loading, message_toast} from 'src/shared/reactstrap-toolbox'

export default ({match, user, location}) => {
  const {token} = match.params
  const {search} = location
  const next_url = new URLSearchParams(search).get('next')
  const [redirect, setRedirect] = React.useState(null)
  const anon_user = user === false

  useEffect(() => {
    if (anon_user) {
      window.app.requests
        .post('/api/dashboard/create-account/confirm/', {token}, {expected_status: [200, 404, 409]})
        .then(r => {
          const {message, authenticated} = r.data
          if (r.status === 200) {
            message_toast({icon: faCheck, title: 'Success', message})
            if (authenticated) {
              window.app.reAuthenticate()
            } else {
              setRedirect(`/login/${search}`)
            }
          } else {
            setRedirect(`/create-account/${search}`)
            message_toast({icon: faExclamationTriangle, title: 'Invalid Link', message})
          }
        })
    }
  }, [token, anon_user, search])

  if (user) {
    return <Redirect to={next_url || '/'} push={false} />
  } else if (redirect) {
    return <Redirect to={redirect} push={false} />
  } else {
    return <Loading />
  }
}
